import { cva } from 'class-variance-authority';
import type { ComponentPropsWithoutRef, PropsWithChildren } from 'react';

export type HeadingType = 'h2' | 'h3' | 'h4' | 'h5';

export type HeadingProps<C extends HeadingType = 'h2'> = {
  className?: string;
  size?: C;
} & ComponentPropsWithoutRef<C> &
  PropsWithChildren;

export function Heading<C extends HeadingType = 'h2'>({
  size,
  className,
  ...props
}: HeadingProps<C>) {
  const As = size ?? 'h2';
  return <As className={heading({ size, className })} {...props} />;
}

const heading = cva('font-poppins font-light mb-1', {
  variants: {
    size: {
      h2: 'text-heading-2 sm:text-heading-2-sm',
      h3: 'text-heading-3',
      h4: 'text-heading-4',
      h5: 'text-heading-5',
    },
  },
  defaultVariants: {
    size: 'h2',
  },
});
